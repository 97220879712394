import { useEffect } from "react";
import {
  useLocalParticipant,
  useMediaDeviceSelect,
} from "@livekit/components-react";
import { Flex, Box, Heading } from "@springcare/sh-component-library";
import { DeviceTypes } from "components/templates/SHSessionRoom/types";
import SessionRoomGrid from "components/templates/SHSessionRoom/components/SessionRoomGrid";
import SessionRoomDrawer from "components/templates/SHSessionRoom/components/SessionRoomDrawer/SessionRoomDrawer";
import { VideoCallControls } from "components/templates/SHSessionRoom/components/VideoCallControls";
import {
  useSessionDevices,
  useSessionRoomDrawer,
  useUpdateChatMessages,
} from "context/SessionRoomContext";
import {
  trackPageVersionViewed,
  trackSessionDrawerToggle,
} from "components/templates/SHSessionRoom/telemetry/analytics";
import { useTranslation } from "hooks/react-i18next";

const SessionRoomStage = ({ isMobile }) => {
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "sessionRoomStage",
  });
  const yourCareSessionLabel = t("yourCareSession");

  const { sessionDevices } = useSessionDevices();
  const {
    isSessionRoomDrawerOpen,
    onSessionRoomDrawerClose,
    activeDrawerTab,
    drawerWidth,
  } = useSessionRoomDrawer();
  const { localParticipant } = useLocalParticipant();

  const { setActiveMediaDevice: setActiveAudioInputDevice } =
    useMediaDeviceSelect({
      kind: DeviceTypes.AudioInput,
    });
  const { setActiveMediaDevice: setActiveVideoInputDevice } =
    useMediaDeviceSelect({
      kind: DeviceTypes.VideoInput,
    });
  const { setActiveMediaDevice: setActiveAudioOutputDevice } =
    useMediaDeviceSelect({
      kind: DeviceTypes.AudioOutput,
    });

  // update chat messages and forward the send, chatMessages, and isSending functions / objects
  // to decrease an extra hook call here to useChat()
  const { send, chatMessages, isSending } = useUpdateChatMessages();
  const handleEscapeKeyPress = (e) => {
    if (e.key === "Escape" && isSessionRoomDrawerOpen) {
      onSessionRoomDrawerClose();
      trackSessionDrawerToggle(activeDrawerTab, false, false);
    }
  };

  useEffect(() => {
    localParticipant.setCameraEnabled(sessionDevices.isCameraEnabled);
    localParticipant.setMicrophoneEnabled(sessionDevices.isMicrophoneEnabled);
    setActiveAudioOutputDevice(
      sessionDevices.selectedAudioOutputDevice.deviceId,
    );
    setActiveAudioInputDevice(sessionDevices.selectedAudioInputDevice.deviceId);
    setActiveVideoInputDevice(sessionDevices.selectedVideoDevice.deviceId);
  }, []);

  useEffect(() => {
    trackPageVersionViewed("meeting_room");
  }, []);

  useEffect(() => {
    // There's extra height on mobile which we haven't yet found the cause of. This makes sure
    // that Mobile users see all content
    const bottom = document.documentElement.scrollHeight - window.innerHeight;
    window.scrollTo(0, bottom);
  }, []);

  useEffect(() => {
    // There's extra height on mobile which we haven't yet found the cause of.
    // When the Drawer is open it needs to be scrolled to the top. When closed,
    // we return.
    window.addEventListener("keydown", handleEscapeKeyPress);
    if (isSessionRoomDrawerOpen) {
      window.scrollTo(0, 0);
    } else {
      const bottom = document.documentElement.scrollHeight - window.innerHeight;
      window.scrollTo(0, bottom);
    }

    return () => {
      window.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, [isSessionRoomDrawerOpen]);

  return (
    <Flex direction="column" h="100vh" w="100vw" overflow="hidden">
      <Heading
        as="h1"
        position="absolute"
        aria-hidden="true"
        aria-label={yourCareSessionLabel}
        width="1px"
        height="1px"
        overflow="hidden"
      >
        {yourCareSessionLabel}
      </Heading>
      <Flex flex={1} overflow="hidden" position="relative">
        <Box
          flex={1}
          width={
            isSessionRoomDrawerOpen && !isMobile
              ? `calc(100% - ${drawerWidth}px)`
              : "100%"
          }
          overflow="hidden"
        >
          <SessionRoomGrid
            isMobile={isMobile}
            drawerWidth={isSessionRoomDrawerOpen && !isMobile ? drawerWidth : 0}
          />
        </Box>
        {isSessionRoomDrawerOpen && (
          <SessionRoomDrawer
            send={send}
            chatMessages={chatMessages}
            isSending={isSending}
            isMobile={isMobile}
          />
        )}
      </Flex>

      <Flex
        justify={isSessionRoomDrawerOpen ? "start" : "center"}
        pt="v-4"
        pb="v-8"
        bg={isMobile ? "transparent" : "black"}
      >
        <VideoCallControls />
      </Flex>
    </Flex>
  );
};

export default SessionRoomStage;
